import { getValueFromDto as getValue } from './_helpers.js';

export default class WasteTypeAccepted {
	constructor(dto) {
		this.wasteTypeId = getValue(dto, 'wasteTypeId', 'number', null);
		this.wasteType = null;
		this.disposalFee = getValue(dto, 'disposalFee', 'number', 0);
		this.disposalFeePerGallon = getValue(dto, 'disposalFeePerGallon', 'boolean', true);
		Object.defineProperty(this, 'wasteType', { enumerable: false });
	}
}
