import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class WasteDisposalSiteSeason {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.cropCode = getValue(dto, 'cropCode', 'string', '');
		this.dateOpen = getDate(dto, 'dateOpen', null);
		this.dateClose = getDate(dto, 'dateClose', null);
		this.loadingRate = getValue(dto, 'loadingRate', 'number', 0);
		this.acres = getValue(dto, 'acres', 'number', 0);
		this.capacityAvailable = getValue(dto, 'capacityAvailable', 'number', 0);
		this.capacityUsed = getValue(dto, 'capacityUsed', 'number', 0);
		this.capacityWarningLevel = getValue(dto, 'capacityWarningLevel', 'number', 0);
		this.siteFull = getValue(dto, 'siteFull', 'boolean', false);
	}

	get capacityRemaining() {
		return this.capacityAvailable - this.capacityUsed;
	}
}
