import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helpers.js';

export default class PhAdjustmentMeasurement {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.alkalineTypeUsed = getValue(dto, 'alkalineTypeUsed', 'string', '');
		this.initialTimestamp = getDate(dto, 'initialTimestamp', null);
		this.initialTemperature = getValue(dto, 'initialTemperature', 'number', 0);
		this.initialPh = getValue(dto, 'initialPh', 'number', 0);
		this.initialCorrectionFactor = getValue(dto, 'initialCorrectionFactor', 'number', 0);
		this.initialCorrectedPh = getValue(dto, 'initialCorrectedPh', 'number', 0);
		this.finalTimestamp = getDate(dto, 'finalTimestamp', null);
		this.finalTemperature = getValue(dto, 'finalTemperature', 'number', 0);
		this.finalPh = getValue(dto, 'finalPh', 'number', 0);
		this.finalCorrectionFactor = getValue(dto, 'finalCorrectionFactor', 'number', 0);
		this.finalCorrectedPh = getValue(dto, 'finalCorrectedPh', 'number', 0);
	}
}
