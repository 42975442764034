import { getArrayOfObjectsFromDto as getTypedArray, getDateTimeFromDto as getDate, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';

import Contact from './Contact.js';
import FileReference from './FileReference.js';
import WasteDisposalSiteType from './WasteDisposalSiteType.js';
import WasteTypeAccepted from './WasteTypeAccepted.js';
import WasteDisposalSiteSeason from './WasteDisposalSiteSeason.js';
import { DateTime } from 'luxon';

export default class WasteDisposalSite {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.active = getValue(dto, 'active', 'boolean', true);
		this.wasteDisposalSiteTypeId = getValue(dto, 'wasteDisposalSiteTypeId', 'number', null);
		this.wasteDisposalSiteType = getObject(dto, 'wasteDisposalSiteType', WasteDisposalSiteType, null);
		Object.defineProperty(this, 'wasteDisposalSiteType', { enumerable: false });
		this.permitNumber = getValue(dto, 'permitNumber', 'string', '');
		this.street1 = getValue(dto, 'street1', 'string', '');
		this.street2 = getValue(dto, 'street2', 'string', '');
		this.city = getValue(dto, 'city', 'string', '');
		this.township = getValue(dto, 'township', 'string', '');
		this.county = getValue(dto, 'county', 'string', '');
		this.state = getValue(dto, 'state', 'string', '');
		this.postalCode = getValue(dto, 'postalCode', 'string', '');
		this.latitude = getValue(dto, 'latitude', 'number', 0);
		this.longitude = getValue(dto, 'longitude', 'number', 0);
		this.requireVehiclePermit = getValue(dto, 'requireVehiclePermit', 'boolean', false);
		this.landDisposal = getValue(dto, 'landDisposal', 'boolean', false);
		this.notes = getValue(dto, 'notes', 'string', '');
		this.seasons = getTypedArray(dto, 'seasons', WasteDisposalSiteSeason, []);
		this.seasons.sort((a, b) => b.dateOpen - a.dateOpen);
		this.contacts = getTypedArray(dto, 'contacts', Contact, []);
		this.attachments = getTypedArray(dto, 'attachments', FileReference, []);
		this.wasteTypesAccepted = getTypedArray(dto, 'wasteTypesAccepted', WasteTypeAccepted, []);

		this._currentSeason = undefined;
		Object.defineProperty(this, '_currentSeason', { enumerable: false });
		this._nextSeason = undefined;
		Object.defineProperty(this, '_nextSeason', { enumerable: false });
	}

	get currentSeason() {
		if (this._currentSeason === undefined) {
			this._currentSeason = this.getCurrentSeason();
		}
		return this._currentSeason;
	}

	getCurrentSeason(timestamp) {
		if (this.landDisposal && Array.isArray(this.seasons) && this.seasons.length > 0) {
			if (!(timestamp instanceof DateTime)) {
				timestamp = DateTime.now();
			}
			return this.seasons.find(x => x.dateOpen <= timestamp && timestamp < x.dateClose) ?? null;
		} else {
			return null;
		}
	}

	get nextSeason() {
		if (this._nextSeason === undefined) {
			const now = DateTime.now();
			this._nextSeason = this.seasons.filter(x => x.dateOpen >= now).sort((a, b) => a.dateOpen - b.dateOpen)[0] ?? null;
		}
		return this._nextSeason;
	}

	get address() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? this.street2 + ', ' : '') + (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}

	get addressLine1() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? ', ' + this.street2 + ', ' : '')
	}

	get addressLine2() {
		return (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}
}
