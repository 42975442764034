import { makeComparator } from '@/helpers/helpers';
import { getIdb, idbHelpers, localChanges, localChangesInUse } from '@/idb';
import WasteDisposalSite from '@/models/WasteDisposalSite';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'wasteDisposalSites';

export default {
	/**
	 * Get all waste disposal sites
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of WasteDisposalSite objects if the request was successful, otherwise a Response.
	 */
	async getAll({ active = undefined } = {}) {
		const filters = {};
		if (typeof active === 'boolean') {
			filters.active = active;
		}
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchAllPages('/api/WasteDisposalSites', x => data.push(x));
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		const filter = 'active' in filters ? (x => x.active === filters.active) : undefined;
		if (useIdb && idb) {
			if (filter) {
				data = await idbHelpers.getFiltered(idb, idbStore, filter);
			} else {
				data = await idb.getAll(idbStore);
			}
			response = idbResponse(200);
		}
		if (response.ok) {
			if (!isIdbResponse(response)) {
				if (idb) {
					await idbHelpers.replaceAll(idb, idbStore, data);
					await cache.setTimestamp(idbStore, timestamp);
				}
				if (idb && localChangesInUse.value) {
					await localChanges.replaceDataIfChanged(idb, idbStore, data, filter);
				}
				if (filter) {
					data = data.filter(filter);
				}
			}
			data.sort(makeComparator('id'));
			return data.map(x => new WasteDisposalSite(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a waste disposal site
	 * @param {Number} id WasteDisposalSite ID
	 * @returns (async) Returns a WasteDisposalSite if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		const idb = await getIdb();
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore) || (idb && localChangesInUse.value && await localChanges.isDataChanged(idb, idbStore, id));
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			if ((data = (await this.getAll()).find(x => x.id === id) ?? null)) {
				return data;
			} else {
				throw idbResponse(404);
			}
		}
		if (!useIdb) {
			try {
				response = await fetchWrap('/api/WasteDisposalSites/' + id);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.get(idbStore, id);
			response = data ? idbResponse(200) : idbResponse(404);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data);
				// await filesApi.storeInIdb(data.attachments);
			}
			return new WasteDisposalSite(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a waste disposal site
	 * @param {WasteDisposalSite} model waste disposal site to create.
	 * @returns (async) Returns the new WasteDisposalSite if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/WasteDisposalSites', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new WasteDisposalSite(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a waste disposal site
	 * @param {WasteDisposalSite} model waste disposal site to update.
	 * @returns (async) Returns the updated WasteDisposalSite if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/WasteDisposalSites/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new WasteDisposalSite(model);
		} else {
			return response;
		}
	},
	/**
	 * Update a waste disposal site
	 * @param {WasteDisposalSite} model waste disposal site to update.
	 * @param {WasteDisposalSite} oldModel old waste disposal site to determine updates to apply.
	 * @returns (async) Returns the updated WasteDisposalSite if the request was successful, otherwise a Response.
	 */
	async patch(model, oldModel) {
		const oldData = JSON.parse(JSON.stringify(oldModel));
		const newData = JSON.parse(JSON.stringify(model));
		const patch = jsonPatchCompare(oldData, newData);
		if (patch.length === 0) {
			return model instanceof WasteDisposalSite ? model : new WasteDisposalSite(model);
		}
		let response;
		try {
			response = await fetchWrap('/api/WasteDisposalSites/' + model.id, {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(patch),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new WasteDisposalSite(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a waste disposal site
	 * @param {Number} id WasteDisposalSite ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the waste disposal site could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/WasteDisposalSites/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
