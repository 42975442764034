import { getArrayFromDto, getArrayOfObjectsFromDto as getTypedArray, getDateTimeFromDto as getDate, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';
import PhAdjustmentMeasurement from './PhAdjustmentMeasurement.js';
import User from './User.js';
import Vehicle from './Vehicle.js';
import WasteDisposalSite from './WasteDisposalSite.js';
import WasteType from './WasteType.js';
import WorkOrder from './WorkOrder.js';

export default class WasteDisposal {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.wasteDisposalSiteId = getValue(dto, 'wasteDisposalSiteId', 'number', null);
		this.wasteDisposalSite = getObject(dto, 'wasteDisposalSite', WasteDisposalSite, null);
		this.timestamp = getDate(dto, 'timestamp', null);
		this.wasteTypeId = getValue(dto, 'wasteTypeId', 'number', null);
		this.wasteType = getObject(dto, 'wasteType', WasteType, null);
		this.amountDisposed = getValue(dto, 'amountDisposed', 'number', 0);
		this.disposalFee = getValue(dto, 'disposalFee', 'number', 0);
		this.permitNumber = getValue(dto, 'permitNumber', 'string', null);
		this.vehiclePermitNumber = getValue(dto, 'vehiclePermitNumber', 'string', null);
		this.operatorInitials = getValue(dto, 'operatorInitials', 'string', null);
		this.operatorId = getValue(dto, 'operatorId', 'number', null);
		this.operator = getObject(dto, 'operator', User, null);
		this.vehicleId = getValue(dto, 'vehicleId', 'number', null);
		this.vehicle = getObject(dto, 'vehicle', Vehicle, null);
		this.workOrderIds = getArrayFromDto(dto, 'workOrderIds', []);
		this.workOrders = getTypedArray(dto, 'workOrders', WorkOrder, []);
		this.vectorAttractionReductionMethod = getValue(dto, 'vectorAttractionReductionMethod', 'number', null);
		this.phAdjustmentMeasurements = getObject(dto, 'phAdjustmentMeasurements', PhAdjustmentMeasurement, null);
		this.vectorAttractionReductionTimestamp = getDate(dto, 'vectorAttractionReductionTimestamp', null);
		this.vectorAttractionReductionName = getValue(dto, 'vectorAttractionReductionName', 'string', null);
		this.pathogenReductionMethod = getValue(dto, 'pathogenReductionMethod', 'number', null);

		Object.defineProperty(this, 'wasteDisposalSite', { enumerable: false });
		Object.defineProperty(this, 'wasteType', { enumerable: false });
		// Object.defineProperty(this, 'disposalFee', { enumerable: false });
		// Object.defineProperty(this, 'permitNumber', { enumerable: false });
		// Object.defineProperty(this, 'vehiclePermitNumber', { enumerable: false });
		// Object.defineProperty(this, 'operatorInitials', { enumerable: false });
		Object.defineProperty(this, 'operator', { enumerable: false });
		Object.defineProperty(this, 'vehicle', { enumerable: false });
		Object.defineProperty(this, 'workOrders', { enumerable: false });
	}
}
